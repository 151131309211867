import React from 'react';
import { Helmet } from 'react-helmet';
import ServiceInfoCard from '../Cards/ServiceInfoCard';
import { SERVICES } from '../../utils/data';

const OurServices = () => {
	return (
		<section className='max-w-[1200px] py-16 px-4 mx-auto' id='services'>
			<Helmet>
				<meta charSet='utf-8' />
				<title>Services | Alterations by Toni</title>
				<meta
					name='description'
					content="Expert bridal, men's, formal, and custom alterations in Cincinnati by Alterations by Toni. Specializing in bridal gown alterations, men's suit tailoring, formal wear adjustments, and personalized fittings. Visit us for top-quality tailoring and a perfect fit, guaranteed."
				/>
				<meta
					name='keywords'
					content="Bridal Alteration, Wedding Gown Alteration, Men's Alteration, Custom Tailoring, Formal Attire Alteration, Cincinnati Tailoring Services, Gown Preservation, Suit Tailoring, Professional Alterations, Personalized Fitting"
				/>
				<meta name='robots' content='index, follow' />
				<meta name='author' content='Alterations by Toni' />
				<meta property='og:title' content='Alterations by Toni - Your Cincinnati Tailoring Experts' />
				<meta
					property='og:description'
					content="From bridal gown alterations to men's suit tailoring, Alterations by Toni in Cincinnati offers precision and perfection in all our services. Contact us for custom fits, gown preservation, and more."
				/>
				<meta property='og:type' content='website' />
				<meta property='og:url' content='https://www.alterationsbytoni.com/ourServices' />
			</Helmet>
			<h5 className='text-2xl font-semibold text-darkBlue text-center mb-10'>Our Services</h5>

			<div className='bg-white lg:shadow-xl lg:shadow-slate-200/50'>
				{SERVICES.map((item) => (
					<ServiceInfoCard key={item.title} imgUrl={item.imgUrl} title={item.title} description={item.description} description2={item.description2} />
				))}
			</div>
		</section>
	);
};

export default OurServices;
