import React from 'react';
import { Gallery } from 'react-grid-gallery';

import GalleryImg1 from '../assets/our-work/Img-1.jpg';
import GalleryImg2 from '../assets/our-work/Img-2.jpg';
import GalleryImg3 from '../assets/our-work/Img-3.jpg';
import GalleryImg4 from '../assets/our-work/Img-4.jpg';
import GalleryImg5 from '../assets/our-work/Img-5.jpg';
import GalleryImg6 from '../assets/our-work/Img-6.jpg';
import GalleryImg7 from '../assets/our-work/Img-7.jpg';
import GalleryImg8 from '../assets/our-work/Img-8.jpg';
import GalleryImg9 from '../assets/our-work/Img-9.jpg';
import GalleryImg10 from '../assets/our-work/Img-10.jpg';
import GalleryImg11 from '../assets/our-work/Img-11.jpg';
import GalleryImg12 from '../assets/our-work/Img-12.jpg';

const OurWork = () => {
	return (
		<section className=' max-w-[1200px] pt-8 mx-auto h-95' id='our-work'>
			<h5 className='text-2xl font-semibold text-darkBlue text-center mb-16'>Our Work</h5>

			<div className='workGalery grid grid-cols-1 gap-2 md:grid-cols-4 md:grid-rows-2 md:m-4'>
				<div className='m-2 md:m-0 md:col-span-1'>
					<img src={GalleryImg1} alt='Gallery Image 1' className='gallery-img max-w-[400px]  mx-auto' />
				</div>

				<div className='m-2 md:m-0 md:col-span-1'>
					<img src={GalleryImg2} alt='Gallery Image 2' className='gallery-img max-w-[400px]  mx-auto' />
				</div>

				<div className='m-2 md:m-0 md:col-span-1'>
					<img src={GalleryImg3} alt='Gallery Image 3' className='gallery-img max-w-[400px] mx-auto' />
				</div>

				<div className='m-2 md:m-0 md:col-span-1'>
					<img src={GalleryImg4} alt='Gallery Image 4' className='gallery-img max-w-[400px] mx-auto' />
				</div>

				<div className='m-2 md:m-0 md:col-span-1'>
					<img src={GalleryImg5} alt='Gallery Image 5' className='gallery-img max-w-[400px]  mx-auto' />
				</div>

				<div className='m-2 md:m-0 md:col-span-1'>
					<img src={GalleryImg6} alt='Gallery Image 6' className='gallery-img max-w-[400px]  mx-auto' />
				</div>

				<div className='m-2 md:m-0 md:col-span-1'>
					<img src={GalleryImg7} alt='Gallery Image 7' className='gallery-img max-w-[400px]  mx-auto' />
				</div>

				<div className='m-2 md:m-0 md:col-span-1'>
					<img src={GalleryImg8} alt='Gallery Image 8' className='gallery-img max-w-[400px]  mx-auto' />
				</div>

				<div className='m-2 md:m-0 md:col-span-1'>
					<img src={GalleryImg9} alt='Gallery Image 9' className='gallery-img max-w-[400px]  mx-auto' />
				</div>

				<div className='m-2 md:m-0 md:col-span-1'>
					<img src={GalleryImg10} alt='Gallery Image 10' className='gallery-img max-w-[400px]  mx-auto' />
				</div>

				<div className='m-2 md:m-0 md:col-span-1'>
					<img src={GalleryImg11} alt='Gallery Image 11' className='gallery-img max-w-[400px]  mx-auto' />
				</div>
				<div className='m-2 md:m-0 md:col-span-1'>
					<img src={GalleryImg12} alt='Gallery Image 12' className='gallery-img max-w-[400px]  mx-auto' />
				</div>
			</div>
		</section>
	);
};

export default OurWork;
