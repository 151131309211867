import React from 'react';
import logo from '../assets/alterations-by-toni-logo_transparent.png';
import { Link } from 'react-scroll';
import { Link as RouterLink } from 'react-router-dom';

const Footer = () => {
	return (
		<>
			<div className='max-w-[1200px] mx-auto bg-dimBlue'>
				<div className='flex flex-col items-center justify-center p-8'>
					<RouterLink to='/'>
						<img className='w-20 mb-6' src={logo} alt='logo' />
					</RouterLink>

					<ul className='hidden md:flex md:gap-14 '>
						<li>
							<RouterLink to='/' className='text-xs text-darkBlue hover:font-medium'>
								Home
							</RouterLink>
						</li>

						<li>
							<RouterLink to='/about-us' className='text-xs text-darkBlue hover:font-medium'>
								About Us
							</RouterLink>
						</li>

						<li>
							<RouterLink to='/ourServices' className='text-xs text-darkBlue hover:font-medium'>
								Services
							</RouterLink>
						</li>
						<li>
							<RouterLink to='https://my-business-103795-102652.square.site/' target='_blank' className='text-xs text-darkBlue hover:font-medium'>
								Booking
							</RouterLink>
						</li>

						<li>
							<RouterLink to='/contact-us' className='text-xs text-darkBlue hover:font-medium'>
								Contact Us
							</RouterLink>
						</li>
					</ul>
				</div>
			</div>

			<div className='bg-darkBlue text-xs text-white text-center p-4'>© 2024. All Rights Reserved</div>
		</>
	);
};

export default Footer;
