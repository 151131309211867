import React from 'react';
import { Helmet } from 'react-helmet';
import GalleryImg12 from '../../assets/AlterationBytoni.jpg';
import Testimonials from '../Testimonials';

const AboutUs = () => {
	return (
		<section className='bg-secondary mx-auto px-4 sm:px-6 lg:px-8 py-8' id='about-us'>
			<Helmet>
				<meta charSet='utf-8' />
				<title>About Us | Alterations by Toni</title>
				<meta
					name='description'
					content='Discover Alterations by Toni, your Cincinnati tailor specialists. We offer high-quality alterations for everything from jeans to wedding gowns, ensuring customer satisfaction and perfect fit every time. Visit our shop in Madeira, OH, near Kenwood Towne Center.'
				/>
				<meta
					name='keywords'
					content="Cincinnati Tailoring, Alteration Specialists, Wedding Gown Alterations, Professional Seamstresses, Custom Tailoring, Bridal Alterations, Men's and Women's Clothing Alterations, Quality Tailoring Services, Madeira Ohio Tailoring"
				/>
				<meta name='robots' content='index, follow' />
				<meta name='author' content='Alterations by Toni' />
				<meta property='og:title' content='About Alterations by Toni - Expert Tailoring in Cincinnati' />
				<meta
					property='og:description'
					content='Meet the team behind Alterations by Toni, dedicated to high-quality garment alterations in Cincinnati. From bridal gowns to everyday wear, our focus on detail and customer satisfaction sets us apart.'
				/>
				<meta property='og:type' content='website' />
				<meta property='og:url' content="[your website's URL/about-us]" />
			</Helmet>
			<div className='text-center mb-10'>
				<h5 className='text-2xl font-semibold text-darkBlue text-center mb-16'>About Us</h5>
			</div>
			<div className='flex flex-col md:flex-row items-center justify-center md:space-x-6'>
				<div className='md:w-1/2'>
					<img src={GalleryImg12} alt='About Us' className='rounded-lg shadow-lg' />
				</div>
			</div>
			<div className='flex flex-col md:flex-row items-center justify-center md:space-x-6 mt-8'>
				<div className='md:w-1/2 mt-6 md:mt-0'>
					<p className='text-sm leading-6  text-darkBlue mb-6 md:mx-auto md:w-4/5 hero-text'>
						We are Alteration and Tailor Specialists in Cincinnati that focus on high standards, personal service and satisfaction on everything from jeans to designer
						garments to Wedding Gown Alterations which relates to our high customer retention rate. No appointments needed, except for bridal gowns. Mojdeh and her team
						of knowledgeable and experienced seamstresses in Cincinnati are committed to providing perfection in alterations. You will be comfortable knowing your
						alterations will be done perfectly.
					</p>

					<p className='text-sm leading-6  text-darkBlue mb-6 md:mx-auto md:w-4/5 hero-text'>
						Our quality, attention to detail and excellent customer service are known throughout the Cincinnati area. A small business depends on word of mouth
						advertising, and we frequently have customers refer us to their friends, places of work, etc., because it is hard to find a good tailor that excels in doing
						alterations you can depend on. Our shop has top quality, professional tailoring equipment to meet all of your alteration needs.
					</p>

					<p className='text-sm leading-6  text-darkBlue mb-6 md:mx-auto md:w-4/5 hero-text'>
						Whether you are a bride and want to look perfect on your wedding day, need your formal clothing taken in, have a pair of ladies or mens pants hemmed, you
						won’t go wrong coming to Alterations by Toni.
					</p>

					<p className='text-sm leading-6  text-darkBlue mb-6 md:mx-auto md:w-4/5 hero-text'>
						For the brides we offer wedding gown preservation and the gown will be repaired, cleaned and preserved. We are conveniently located in Madeira, OH just a
						few minutes from Kenwood Towne Center.
					</p>
				</div>
			</div>
			<Testimonials isTitle={false} />
		</section>
	);
};

export default AboutUs;
