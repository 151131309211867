import React from 'react';
import StarIcon from '../../assets/star-yellow-icon.svg';

const TestimonialCard = ({ name, imgUrl, review, description, link_to_description }) => {
	const MAX_LENGTH = 200; // Set your desired character limit

	const truncatedDescription = description.length > MAX_LENGTH ? `${description.substring(0, MAX_LENGTH)}... ` : description;

	const isTruncated = description.length > MAX_LENGTH;

	return (
		<div className='bg-white rounded-md p-5 border mx-2'>
			<div className='flex items-center gap-3 mb-4'>
				<img src={imgUrl} alt={`review from ${name}`} className='w-14 h-14 md:w-16 md:h-16 rounded-full' />

				<div>
					<p className='text-sm md:text-base mb-1'>{name}</p>

					<div className='flex items-end gap-[2px]'>
						<img src={StarIcon} className='w-4 h-4 object-contain' />
						<img src={StarIcon} className='w-4 h-4 object-contain' />
						<img src={StarIcon} className='w-4 h-4 object-contain' />
						<img src={StarIcon} className='w-4 h-4 object-contain' />
						<img src={StarIcon} className='w-4 h-4 object-contain' />
					</div>
				</div>
			</div>

			<h6 className='text-sm md:text-base font-medium mb-1'>{review}</h6>

			<span className='text-xs md:text-[13px] text-slate-500'>
				{truncatedDescription}
				{isTruncated && (
					<a className='testimonial_link' href={link_to_description} target='_blank'>
						Read more
					</a>
				)}
			</span>
		</div>
	);
};

export default TestimonialCard;
