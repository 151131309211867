import Icon2 from '../assets/people-svgrepo-com.svg';
import Icon3 from '../assets/icon3.svg';
import Icon4 from '../assets/user-heart-svgrepo-com.svg';

import ServiceImg1 from '../assets/tailoring.jpeg';
import ServiceImg2 from '../assets/man-alteration-2.jpeg';
import ServiceImg3 from '../assets/Formal-Alteration.jpeg';
import ServiceImg4 from '../assets/Ladies-Alteration.jpeg';
import ServiceImg5 from '../assets/WeddingGownPreservation.jpeg';
import ServiceImg6 from '../assets/custom-Alteration.jpeg';

import ProfileImg1 from '../assets/ProfileImg1.jpeg';
import ProfileImg2 from '../assets/ProfileImg2.png';
import ProfileImg3 from '../assets/ProfileImg3.jpeg';
import ProfileImg4 from '../assets/ProfileImg4.jpeg';
import ProfileImg5 from '../assets/ProfileImg5.jpeg';

export const STATS_DATA = [
	{ iconUrl: Icon2, count: '1K+', label: 'Number of Clients Served per Year' },
	{ iconUrl: Icon3, count: '30+', label: 'Years of Experience' },
	{ iconUrl: Icon4, count: '4.8', label: 'Customer Satisfaction Rate' },
];

export const SERVICES = [
	{
		imgUrl: ServiceImg1,
		title: 'Bridal Alteration',
		description: `We specialize in the fine, delicate sewing your bridal gown requires, with absolute perfection you can trust. When you arrive for your appointment, our entire focus is on you – your wedding is a special day and you deserve to have your gown altered as you wish, in the most professional manner possible. Contact us today for your Bridal Gown Alteration fitting.`,
		description2: `
      <span class="title-ul"> Bridal Alteration Services </span>
      <ul class='description2-list'>
        <li>Custom fit alteration of your gown to fit your shape, and the style of your gown</li>
        <li>Unique bustles to complement the style of your gown</li>
        <li>Final pressing of all layers of your gown</li>
        <li>Corset back – order extra yard of fabric for this look (perfect for a dress that is too small – maintains original details of your dress with no compromise in design)</li>
      </ul>

    `,
	},
	{
		imgUrl: ServiceImg2,
		title: 'Mens Alteration',
		description: `High quality, custom tailoring on your suits, jackets, slacks and coats. By adjusting waists, sides and hems, we can make you look your best and contribute to the overall impression you make on others, your success and your self-esteem. Visit Alterations by Toni for the BEST Men’s Alterations | Tailor in Cincinnati.`,
		description2: `
      <span class="title-ul"> Men's Alteration Services </span>
      <ul class='description2-list'>
        <li>Custom tailoring for suits, jackets, slacks, and coats.</li>
        <li>Adjustments for waists, sides, and hems.</li>
        <li>Services include sleeve and hem adjustments, shoulder fitting, lining replacement for coats/jackets, and alterations for slacks/jeans (hems, waist, seat, crotch, sides).</li>
        <li>Repairs and replacements such as zippers, buttons, snaps, hooks/eyes, mending, and jean patches.</li>
      </ul>

    `,
	},
	{
		imgUrl: ServiceImg3,
		title: 'Formal Alteration',
		description: `A custom fit makes all the difference on your formal attire. We can take your formal clothing that fits “okay” on you to one that looks fantastic! Whether your formal wear needs are.`,
		description2: `
      <span class="title-ul"> Ladies Services</span>
      <ul class='description2-list'>
        <li>Mother of the Bride/Groom</li>
        <li>Bridesmaids and Flower Girls</li>
        <li>Prom, Homecoming, Cotillion</li>
      </ul>


      <span class="title-ul"> Men's Services</span>
      <ul class='description2-list'>
        <li>Men’s Suits and Tuxedo’s</li>
        <li>Attire for Prom and Homecoming.</li>
        <li>Military Uniforms</li>
      </ul>

    `,
	},
	{
		imgUrl: ServiceImg4,
		title: 'Ladies Alteration',
		description: `A custom fit makes all the difference on your tailored business attire, as well as your everyday garments. Ladies Alterations are our specialty and we take a garment that looks okay on you to one that looks fantastic! For example, by simply removing oversize shoulder pads, and altering the shoulder, your jacket will have a completely updated look.`,
		description2: `
      <span class="title-ul"> Ladies Alteration Services </span>
      <ul class='description2-list'>
        <li><span class='title-list'>Coats and Jackets:</span> Adjust sleeves, hems, shoulders, replace linings.</li>
        <li><span class='title-list'>Pants, Slacks, Jeans, Dresses, Skirts:</span> Hem adjustments, waist and side alterations, original jean hems.</li>
        <li><span class='title-list'>Blouses:</span> Modify sleeves (length and fit), hem adjustments.</li>
        <li><span class='title-list'>Repairs and Replacements:</span> Fix zippers, add buttons/snaps/hooks/eyes, mending, patches (jeans, vests, military).</li>
      </ul>

    `,
	},
	{
		imgUrl: ServiceImg5,
		title: 'Wedding Gown Preservation',
		description: `Alterations by Toni is the place for Wedding Gown Preservation in Cincinnati. Our pricing for wedding gown preservation is lower than most local dry cleaners, and comes with a 100 year guarantee against yellowing.

Preserve the cherished memories of your wedding by preserving your wedding gown. The beautiful photos will help you remember your special day, and with your gown preserved you’ll also have it as a beautiful reminder. Preserving your wedding gown can be used for your own memories, or for your daughter or maybe even a granddaughter to wear in the future.`,
	},

	{
		imgUrl: ServiceImg6,
		title: 'Custom Alteration',
		description: `Alterations by Toni offers expert alteration services, ensuring every outfit fits you perfectly. Our services cater to a variety of needs, from minor adjustments to complete garment redesigns. We understand the importance of a perfect fit and are dedicated to providing personalized solutions for each client.

In addition to our alteration expertise, we have a professional fashion designer on staff to assist with all your styling needs. Whether you're customizing an existing piece or creating something new, our designer will guide you through selecting materials, styles, and cuts that flatter your unique body type and personal taste. Our commitment is to help you feel confident and look your best in every outfit.`,
	},
];

export const TESTIMONIALS = [
	{
		id: '01',
		imgUrl: ProfileImg1,
		name: 'Becca Schaberg',
		// review: 'It was a great experience',
		description: `Mojdeh was kind, attentive, thorough, and highly professional throughout my experience with her and the entire staff at Alterations by Toni for having my wedding dress altered. They were able to fit me in on a semi-truncated timeline, and I’m so glad I chose to trust them with my alteration needs!`,
		link_to_description: 'https://g.co/kgs/JNo59Vy',
	},
	{
		id: '02',
		imgUrl: ProfileImg2,
		name: 'Natasha Reolon',
		// review: 'Incredible journey. Highly recommend!',
		description: `Let me just start off saying, you are in the right spot!! I got lucky, when I found my dress, it was a sample dress and had to be at least a size or 2 big.. so I knew it needed adjustments.. and when I met Mojdeh, I knew my dress was in good hands. I just came from the second dress fitting and WOW. She truly made it MY dress. I feel comfortable, it fits beautifully, and she added a few details to the bustle to make it easy to find. I feel confident and ready to get married and am truly greatful to meet such lovely people.`,
		link_to_description: 'https://g.co/kgs/NwGvQH7',
	},
	{
		id: '03',
		imgUrl: ProfileImg3,
		name: 'Joelle Watson',
		// review: 'Truly great. Exceeded expectations',
		description: `Mojdeh and the team at Alterations by Toni are wonderful! My dress was 4 inches too large as I was between sizes, and her team made it look like it was made for me!! The bustle and all of the detail work is just absolutely incredible. I highly recommend Alterations by Toni, they make everything so easy and stress free. 🙂 `,
		link_to_description: 'https://g.co/kgs/WC6mnDa',
	},
	{
		id: '04',
		imgUrl: ProfileImg4,
		name: 'Szu-Aun Lim',
		// review: 'Truly great. Exceeded expectations',
		description: `Couldn’t have been more pleased with my experience here. Mojdeh made amazing alterations to my dress and created an incredible bustle that took 30 seconds on wedding day. Everyone here is so friendly. I couldn’t recommend anyone more highly. Thanks Mojdeh! `,
		link_to_description: 'https://g.co/kgs/DXGHDcd',
	},
	{
		id: '05',
		imgUrl: ProfileImg5,
		name: 'Lauren Castner',
		// review: 'Truly great. Exceeded expectations',
		description: `I couldn't be happier with my alterations! My dress has such delicate fabric and boning but the alterations made it look like it was made to fit me and was perfect! Modjeh was amazing to work with! She explained everything in detail and really cared about my dress. I can't wait to wear it on my wedding day!`,
		link_to_description: 'https://g.co/kgs/Y3PSLg2',
	},
];
