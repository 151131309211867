import React, { useState } from 'react';
import logo from '../assets/alterations-by-toni-logo_transparent.png';
import { RiMenu4Line, RiCloseFill } from 'react-icons/ri';
import MobileNavbar from './MobileNavbar';
import { Link as RouterLink } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { FaFacebook } from 'react-icons/fa';
import { FaInstagramSquare } from 'react-icons/fa';

const Navbar = () => {
	const [isMenuOpen, setIsMenuOpen] = useState();
	const location = useLocation(); // Get the current location

	const isActive = (path) => {
		// Check if the path matches the current location
		return location.pathname === path;
	};

	const toggleMenu = () => {
		setIsMenuOpen(!isMenuOpen);
	};

	return (
		<>
			{isMenuOpen && <MobileNavbar isOpen={isMenuOpen} setIsMenuOpen={setIsMenuOpen} />}

			<div className='bg-background sticky top-0 z-10'>
				<nav className='max-w-screen-xl mx-auto py-4 px-6'>
					<div className='flex items-center justify-between'>
						<RouterLink to='/'>
							<img src={logo} alt='logo' className='h-11 w-auto object-contain' />
						</RouterLink>

						<ul className='hidden md:flex md:gap-14 '>
							<li>
								<RouterLink to='/' className={`menu-item ${isActive('/') ? 'active-class' : ''}`}>
									Home
								</RouterLink>
							</li>

							<li>
								<RouterLink to='/about-us' className={`menu-item ${isActive('/about-us') ? 'active-class' : ''}`}>
									About Us
								</RouterLink>
							</li>

							<li>
								<RouterLink to='/ourServices' className={`menu-item ${isActive('/ourServices') ? 'active-class' : ''}`}>
									Services
								</RouterLink>
							</li>
							<li>
								<RouterLink to='https://my-business-103795-102652.square.site/' target='_blank' className='menu-item'>
									Booking
								</RouterLink>
							</li>

							<li>
								<RouterLink to='/contact-us' className={`menu-item ${isActive('/contact-us') ? 'active-class' : ''}`}>
									Contact Us
								</RouterLink>
							</li>
						</ul>
						<span className='hidden md:flex md:items-center md:justify-between md:inlineBlock '>
							{/* <button className='hidden h-10 bg-primary text-white text-sm px-6 rounded hover:bg-blue-200 hover:text-primary md:block'>Contact Us</button> */}
							<a href='https://www.facebook.com/AlterationsByToni' role='button' className=' mr-4' target='_blank'>
								{/* <!-- Facebook --> */}
								<FaFacebook className='text-2xl' style={{ color: '#1877f2' }} />
							</a>

							<a href='https://www.instagram.com/alterations_by_toni/' target='_blank' role='button'>
								{/* <!-- Instagram --> */}
								<FaInstagramSquare className='text-2xl' style={{ color: '#c13584' }} />
							</a>
						</span>

						<button onClick={toggleMenu} className='w-11 h-11 bg-blue-100 text-2xl text-primary flex items-center justify-center rounded md:hidden z-50'>
							{isMenuOpen ? <RiCloseFill /> : <RiMenu4Line />}
						</button>
					</div>
				</nav>
			</div>
		</>
	);
};

export default Navbar;
