import React from 'react';
import logo from '../assets/alterations-by-toni-logo_transparent.png';
import { Link as RouterLink } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { FaFacebook } from 'react-icons/fa';
import { FaInstagramSquare } from 'react-icons/fa';

const MobileNavbar = ({ setIsMenuOpen }) => {
	const location = useLocation(); // Get the current location

	const isActive = (path) => {
		// Check if the path matches the current location
		return location.pathname === path;
	};
	// const handleScroll = (sectionId) => {
	// 	if (isOpen) setIsMenuOpen(false);
	// 	document.getElementById(sectionId).scrollIntoView({ behavior: 'smooth' });
	// };

	return (
		<div className='w-screen fixed top-0 z-20'>
			<div className='w-1/2 h-screen flex flex-col p-8 bg-background'>
				<img src={logo} alt='logo' className='w-24 object-contain mb-8' />
				<ul>
					<li className='mb-5'>
						<RouterLink to='/' className={`menu-item ${isActive('/') ? 'active-class' : ''}`}>
							Home
						</RouterLink>
					</li>

					<li className='mb-5'>
						<RouterLink to='/about-us' className={`menu-item ${isActive('/about-us') ? 'active-class' : ''}`}>
							About Us
						</RouterLink>
					</li>

					<li className='mb-5'>
						<RouterLink to='/ourServices' className={`menu-item ${isActive('/ourServices') ? 'active-class' : ''}`}>
							Services
						</RouterLink>
					</li>

					<li className='mb-5'>
						<RouterLink to='https://my-business-103795-102652.square.site/' target='_blank' className='menu-item'>
							Booking
						</RouterLink>
					</li>

					<li className='mb-8'>
						<RouterLink to='/contact-us' className={`menu-item ${isActive('/contact-us') ? 'active-class' : ''}`}>
							Contact Us
						</RouterLink>
					</li>
				</ul>
				<span className='flex items-center '>
					{/* <button className='hidden h-10 bg-primary text-white text-sm px-6 rounded hover:bg-blue-200 hover:text-primary md:block'>Contact Us</button> */}
					<a href='https://www.facebook.com/AlterationsByToni' role='button' className=' mr-4' target='_blank'>
						{/* <!-- Facebook --> */}
						<FaFacebook className='text-2xl' style={{ color: '#1877f2' }} />
					</a>

					<a href='https://www.instagram.com/alterations_by_toni/' target='_blank' role='button'>
						{/* <!-- Instagram --> */}
						<FaInstagramSquare className='text-2xl' style={{ color: '#c13584' }} />
					</a>
				</span>
			</div>

			<div
				onClick={() => {
					setIsMenuOpen(false);
				}}
				className='w-screen h-screen bg-overlayBg fixed top-0 -z-10'
			/>
		</div>
	);
};

export default MobileNavbar;
