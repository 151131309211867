import React from 'react';
import Navbar from './components/Navbar';
import Hero from './components/Hero';
import Stats from './components/Stats';
import OurServices from './components/pages/OurServices';
import OurWork from './components/OurWork';
import Testimonials from './components/Testimonials';
import Footer from './components/Footer';
import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import ContactUsPage from './components/pages/ContactUs'; // Import your ContactUsPage component
import ScrollToTop from './components/ScrollToTop';
import AboutUs from './components/pages/AboutUs';
import logo from './assets/logo-black-toni-alterations-darker.jpg';
import { Helmet } from 'react-helmet';

const App = () => {
	return (
		<Router>
			<ScrollToTop />
			<div className='flex flex-col min-h-screen bg-background'>
				<Helmet>
					<meta charSet='utf-8' />
					<title>Alterations by Toni</title>
					<link rel='icon' href={logo} />
					<meta
						name='description'
						content="Alterations by Toni offers professional tailoring and alteration services in Cincinnati. Specializing in bridal gowns, men's suits, and all types of garments. Experience perfection in every stitch with our expert team."
					/>
					<meta
						name='keywords'
						content="Professional Tailoring, Cincinnati Alterations, Bridal Gown Tailoring, Men's Suit Alterations, Quality Garment Alterations, Expert Seamstresses, Tailoring Services, Clothing Adjustments, Custom Fit Clothing, Tailor Shop"
					/>
					<meta name='robots' content='index, follow' />
					<meta name='author' content='Alterations by Toni' />
					<meta property='og:type' content='website' />
					<meta property='og:site_name' content='Alterations by Toni' />
					<meta property='og:url' content='https://www.alterationsbytoni.com/' />
				</Helmet>
				<Navbar />
				<div className='flex-1'>
					<Routes>
						<Route
							path='/'
							element={
								<>
									<Hero />
									<Stats />
									<OurWork />
									<Testimonials isTitle='true' />
								</>
							}
						/>
						<Route path='/about-us' element={<AboutUs />} />
						<Route path='/ourServices' element={<OurServices />} />
						<Route path='/contact-us' element={<ContactUsPage />} />
					</Routes>
				</div>
				<Footer />
			</div>
		</Router>
	);
};

export default App;
