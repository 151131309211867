import React from 'react';
import { Helmet } from 'react-helmet';
import { IoLocationOutline } from 'react-icons/io5';
import { LuPhoneCall } from 'react-icons/lu';
import { TfiEmail } from 'react-icons/tfi';

const ContactUs = () => {
	return (
		<>
			<section className='bg-white py-8'>
				<Helmet>
					<meta charSet='utf-8' />
					<title>Contact Us | Alterations by Toni</title>
					<meta
						name='description'
						content="Get in touch with Alterations by Toni in Cincinnati for all your tailoring needs. Find our location, phone numbers, email, and business hours. We're here to provide top-notch alteration services. Visit us at 7801 Laurel Ave, Cincinnati, OH 45243."
					/>
					<meta
						name='keywords'
						content='Alterations by Toni Contact, Tailoring Services Cincinnati, Alteration Shop Location, Tailor Contact Information, Cincinnati OH Tailors, Business Hours, Clothing Alterations Contact, Tailoring Service Inquiry'
					/>
					<meta name='robots' content='index, follow' />
					<meta name='author' content='Alterations by Toni' />
					<meta property='og:title' content='Contact Alterations by Toni - Your Tailoring Experts in Cincinnati' />
					<meta
						property='og:description'
						content='Reach out to Alterations by Toni for exceptional tailoring services. Visit our Cincinnati location or contact us via phone or email. Our team is ready to assist with your alteration needs.'
					/>
					<meta property='og:type' content='website' />
					<meta property='og:url' content='https://www.alterationsbytoni.com/contact-us' />
				</Helmet>
				<div className='container mx-auto px-6 md:px-12'>
					<div className='flex flex-wrap lg:flex-nowrap justify-between  items-center md:text-left'>
						<div className='w-full mb-6'>
							<div className='flex items-center  md:justify-start'>
								<span className='inline-block p-3 rounded-lg mr-4'>
									<IoLocationOutline className='text-4xl text-blue-600' />
								</span>
								<div>
									<p className='font-bold'>Location</p>
									<a
										href='https://www.google.com/maps/search/?api=1&query=7801+Laurel+Ave.,+Cincinnati,+OH+45243'
										target='_blank'
										rel='noopener noreferrer'
										className='text-slate-600 hover:text-blue-400'
									>
										7801 Laurel Ave, Cincinnati, OH 45243
									</a>
								</div>
							</div>
						</div>

						<div className='w-full mb-6'>
							<div className='flex items-center  md:justify-start'>
								<span className='inline-block p-3 rounded-lg mr-4'>
									<LuPhoneCall className='text-3xl text-blue-600' />
								</span>
								<div>
									<p className='font-bold'>Phone</p>
									<div>
										<div className='flex'>
											<span className='pr-2'>Cell:</span>
											<a href='tel:513-888-0228' className='text-slate-600 hover:text-blue-500 block'>
												513-888-0228
											</a>
										</div>
									</div>
									<div>
										<div className='flex'>
											<span className='pr-2'>Office:</span>
											<a href='tel:513-271-7673' className='text-slate-600 hover:text-blue-500 block'>
												513-271-7673
											</a>
										</div>
									</div>
								</div>
							</div>
						</div>

						<div className='w-full mb-6'>
							<div className='flex items-center  md:justify-start'>
								<span className='inline-block p-3 rounded-lg mr-4'>
									<TfiEmail className='text-3xl text-blue-600' />
								</span>
								<div>
									<p className='font-bold'>Email</p>
									<a href='mailto: support@alterationsbytoni.com' target='_blank' rel='noopener noreferrer' className='text-slate-600 hover:text-blue-500'>
										support@alterationsbytoni.com
									</a>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
			<section className=' py-8'>
				<div className='container mx-auto px-6 lg:px-12'>
					<div className='flex flex-col lg:flex-row justify-center space-y-6 lg:space-y-0 lg:space-x-6'>
						{/* Office Hours */}
						<div className='bg-gray-100 p-6 rounded-lg shadow-md w-full lg:w-1/2 xl:max-w-[650px]'>
							<h5 className='text-xl font-semibold mb-4'>Office Hours</h5>
							<div className='overflow-x-auto'>
								<table className='w-full table-auto text-left'>
									<tbody>
										<tr>
											<td className='px-4 py-2 font-bold'>Monday</td>
											<td className='px-4 py-2 text-slate-600'>9:30 AM – 6:00 PM</td>
										</tr>
										<tr>
											<td className='px-4 py-2 font-bold'>Tuesday</td>
											<td className='px-4 py-2 text-slate-600'>9:30 AM – 6:00 PM</td>
										</tr>
										<tr>
											<td className='px-4 py-2 font-bold'>Wednesday</td>
											<td className='px-4 py-2 text-slate-600'>9:30 AM – 6:00 PM</td>
										</tr>
										<tr>
											<td className='px-4 py-2 font-bold'>Thursday</td>
											<td className='px-4 py-2 text-slate-600'>9:30 AM – 6:00 PM</td>
										</tr>
										<tr>
											<td className='px-4 py-2 font-bold'>Friday</td>
											<td className='px-4 py-2 text-slate-600'>9:30 AM – 3:00 PM</td>
										</tr>
										<tr>
											<td className='px-4 py-2 font-bold'>Saturday</td>
											<td className='px-4 py-2 text-slate-600'>9:30 AM – 3:00 PM</td>
										</tr>
										<tr>
											<td className='px-4 py-2 font-bold'>Sunday</td>
											<td className='px-4 py-2 text-slate-600'>Closed</td>
										</tr>
									</tbody>
								</table>
							</div>
						</div>

						{/* Google Map */}
						<div className='w-full lg:w-1/2 xl:max-w-[650px]'>
							<iframe
								className='w-full h-64 lg:h-96 rounded-lg shadow-md'
								src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3068.336545277914!2d-84.37995968462108!3d39.19891397952759!2m3!1f0!2f0!3f0!3m2!1i1024!1i768!4f13.1!3m3!1m2!1s0x8841b1b9a4d7f3e7%3A0xdef28461f1bf0e02!2s7801%20Laurel%20Ave%2C%20Cincinnati%2C%20OH%2045243%2C%20USA!5e0!3m2!1sen!2s!4v1641247481893'
								frameBorder='0'
								allowFullScreen=''
								aria-hidden='false'
								tabIndex='0'
							></iframe>
						</div>
					</div>
				</div>
			</section>
		</>
	);
};

export default ContactUs;
