import React from 'react';
import { Helmet } from 'react-helmet';
import HeroIMG2 from '../assets/test3.png';
import gownBackgroundImage from '../assets/gownBackgroundImage.png';

const Hero = () => {
	const backgroundStyle = {
		backgroundImage: `url(${gownBackgroundImage})`,
		backgroundSize: 'cover',
		backgroundRepeat: 'no-repeat',
		backgroundPosition: 'center',
		backgroundColor: 'rgba(255, 255, 255, 0.7)', // Adjust the color and opacity as needed
		backgroundBlendMode: 'overlay', // This will blend the background color with the image
	};
	return (
		<section className='relative flex flex-col-reverse gap-12 items-center mx-auto py-0 px-6 md:py-8 lg:py-16 md:flex-row' id='hero' style={backgroundStyle}>
			<Helmet>
				<meta charSet='utf-8' />
				<title>Home | Alterations by Toni</title>
				<meta
					name='description'
					content="Welcome to Alterations by Toni - Cincinnati's premier destination for high-quality alterations, from jeans to designer wear, specializing in bridal gowns. Discover our gallery of work, read testimonials, and learn why our attention to detail and customer satisfaction makes us the trusted choice for all tailoring needs."
				/>
				<meta
					name='keywords'
					content='Alterations by Toni, Tailoring Services Cincinnati, Bridal Gown Alterations, Clothing Alteration Gallery, Customer Testimonials, Designer Wear Alterations, Tailoring Excellence, High-Quality Alterations, Trusted Tailor, Cincinnati Bridal Services'
				/>
				<meta name='robots' content='index, follow' />
				<meta name='author' content='Alterations by Toni' />
				<meta property='og:title' content='Alterations by Toni - Expert Tailoring and Bridal Gown Alterations in Cincinnati' />
				<meta
					property='og:description'
					content='Explore Alterations by Toni for superior tailoring services in Cincinnati. View our work, read client testimonials, and see why our commitment to quality and customer satisfaction sets us apart in bridal and general alterations.'
				/>
				<meta property='og:type' content='website' />
				<meta property='og:url' content='[https://www.alterationsbytoni.com/]' />
			</Helmet>
			<div className='flex-1 md:text-left'>
				<h2 className='text-2xl font-bold text-center text-gradient mb-2 md:text-3xl md:leading-[1.5rem] lg:text-4xl lg:leading-[2rem] companyName'>Welcome to</h2>
				<h3 className='text-2xl font-bold text-center text-gradient mb-4 md:text-3xl md:leading-[3rem] lg:text-4xl lg:leading-[3rem] companyName'> Alterations by Toni</h3>

				<p className='text-md leading-6  text-darkBlue mb-6 md:mx-auto md:max-w-xl hero-text'>
					We specialize in high-quality alterations in Cincinnati, from jeans to designer wear, with a focus on bridal gowns. Our team offers personal service and
					perfection in every stitch. Renowned for attention to detail and customer satisfaction, we're the trusted choice for all your tailoring needs.
				</p>
				<div className='mt-4  mb-2 flex gap-4 text-center justify-center '>
					<a
						href='https://my-business-103795-102652.square.site/'
						target='_blank'
						className='block rounded bg-rose-600 px-12 py-3 text-sm font-medium text-white shadow hover:bg-rose-700 focus:outline-none focus:ring sm:w-auto'
					>
						Booking
					</a>

					{/* <a
						href='https://www.alterationsbytoni.com/about-us'
						className='block w-full rounded bg-blue-600  px-12 py-3 text-sm font-medium text-white  shadow hover:bg-blue-700 focus:outline-none focus:ring sm:w-auto'
					>
						About Us
					</a> */}
				</div>
			</div>

			<div className='flex gap-2 justify-center md:gap-3 lg:gap-5'>
				<img className='hero-img2 ' src={HeroIMG2} alt='' />
			</div>
		</section>
	);
};

export default Hero;
